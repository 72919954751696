@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles.module.css */


.desktop-only {
  @media only screen and (min-width: 768px) {
    /* Show the second and third reels on larger screens */
    display: block;
  }

  /* Hide the reels on smaller screens */
  display: none;
}

/* Center the single reel on small screens */
@media only screen and (max-width: 767px) {
  .centered {
    display: flex;
    justify-content: center;
  }
}